<template>
  <section name="motivos">
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Motivos"
          buttonAddItemText="Novo Motivo"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="motivos"
              :search="searchTextField"
              :loading="loadingTabela"
              items-per-page-text="Linhas por página"
              no-results-text="Nenhum registro encontrado"
              no-data-text="Nenhum registro encontrado"
              mobile-breakpoint="0"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="toggleModalLateral(item)">
                      <v-list-item-title>
                        <v-icon size="20" class="me-2"> mdi-eye </v-icon>
                        <span>Visualizar</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="editar(item)">
                      <v-list-item-title>
                        <v-icon size="20" class="me-2"> mdi-pencil </v-icon>
                        <span>Editar</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="remover(item, true)">
                      <v-list-item-title>
                        <v-icon size="20" class="me-2"> mdi-delete </v-icon>
                        <span>Apagar</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <!-- CREATED_AT -->
              <template v-slot:[`item.created_at`]="{ item }">
                <span>{{ item.created_at | dateTimeFormatBr() }}</span>
              </template>
              <template v-slot:[`item.updated_at`]="{ item }">
                <span>{{ item.updated_at | dateTimeFormatBr() }}</span>
              </template>
              <!-- STATUS -->
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="getColor(item.status)"
                  :class="`${getColor(item.status)}--text`"
                  class="v-chip-light-bg font-weight-semibold text-capitalize"
                >
                  {{
                    item.status == "A" || item.status == "Ativo"
                      ? "Ativo"
                      : "Inativo"
                  }}
                </v-chip>
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">Excluir o registro?</template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadingButtonDelete"
              color="error"
              @click="remover()"
              >Excluir</v-btn
            >
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
          <!--------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <v-container fluid>
              <v-row class="ma-3" wrap>
                <v-col cols="12">
                  <v-text-field
                    ref="descricao"
                    label="Descrição"
                    placeholder="Ex.: Já utilizo um sistema"
                    required
                    outlined
                    dense
                    v-model="motivo.descricao"
                    data-vv-as="descricao"
                    persistent-hint
                    :hint="errors.first('descricao')"
                    :error="errors.collect('descricao').length ? true : false"
                    v-validate="'required|min:3'"
                    data-vv-validate-on="change"
                    id="descricao"
                    name="descricao"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="editedIndex">
                  <v-select
                    ref="status"
                    label="Status"
                    placeholder="Selecione um status"
                    :items="SelectItensStatus"
                    dense
                    outlined
                    v-model="motivo.status"
                    value="A"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="motivo.submotivos"
                    :items="submotivos"
                    item-text="descricao"
                    item-value="id"
                    label="Submotivos"
                    multiple
                    outlined
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.descricao }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                        >(+{{
                          motivo.submotivos && motivo.submotivos.length - 1
                        }}
                        outros)</span
                      >
                    </template>

                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="toggleSelect('submotivos', $event)"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="
                              motivo.submotivos && motivo.submotivos > 0
                                ? 'indigo darken-4'
                                : ''
                            "
                            >{{ iconSelect("submotivos", "submotivo") }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{
                              motivo.submotivos.length != submotivos.length
                                ? "Selecionar todos"
                                : "Desmarcar todos"
                            }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </Crud>
      </v-col>
    </v-row>
    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <!-- Pega todos os submotivos que o motivo dispõem e renderizada todos -->
      <template v-slot:[`item.submotivos`]="{ item }">
        <div v-if="item.submotivos && item.submotivos.length > 0">
          <p v-for="submotivo in item.submotivos" :key="submotivo">
            {{
              submotivos.find((el) => el.id == submotivo) == null
                ? "Não tem submotivos."
                : submotivos.find((el) => el.id == submotivo).descricao
            }}
          </p>
        </div>
        <p v-else>Não tem submotivos.</p>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ item.status == "A" ? "Ativo" : "Inativo" }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}</span
        >
      </template>
    </ModalLateral>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="logo da legiio" />
      </v-progress-circular>
    </v-overlay>
  </section>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
export default {
  name: "PainelMotivos",
  inject: ["$validator"],
  components: { Crud, ModalLateral },
  data: () => ({
    logotipo: logo,
    loadingTabela: false,
    loadingVisualizar: false,
    loadingButtonDelete: false,
    nameForm: "Novo motivo",
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },
    motivo: {
      descricao: "",
      status: "A",
      submotivos: [],
    },
    SelectItensStatus: [
      { text: "Ativo", value: "A" },
      { text: "Inativo", value: "I" },
    ],
    searchTextField: "",
    item: [],
    dialogDelete: false,
    dialog: false,
    editedIndex: false,
    headers: [
      { text: "Descrição", align: "start", value: "descricao" },
      { text: "Data de criação", value: "created_at" },
      { text: "Data de atualização", value: "updated_at" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    headerModalLateral: [
      { text: "Descrição", align: "start", value: "descricao" },
      {
        text: "Submotivos",
        align: "end",
        value: "submotivos",
        sortable: false,
      },
      { text: "Data de criação", value: "created_at" },
      { text: "Status", value: "status" },
    ],
  }),

  created() {
    this.$store.dispatch("motivos/setMotivos");
  },

  computed: {
    motivos: {
      get() {
        return this.$store.getters["motivos/getMotivos"];
      },
    },
    submotivos: {
      get() {
        return this.$store.getters["motivos/getListaSubmotivos"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["motivos/getLoading"];
      },
      set(value) {
        return value;
      },
    },
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },

  methods: {
    //Modal Lateral
    toggleModalLateral(item) {
      this.modalLateral.title = this.modalLateral.show ? "" : item.nome;
      this.modalLateral.item = this.modalLateral.show ? [] : item;
      this.modalLateral.show = this.modalLateral.show ? false : true;
    },
    // O salvar so está salvando, não está editando
    salvar() {
      //this.dialog = false;
      this.$validator.validateAll().then((result) => {
        if (result === true) {
          //this.loading = true;

          if (this.editedIndex == false) {
            this.loadingTabela = true;
            this.$store
              .dispatch("motivos/salvarMotivo", this.motivo)
              .then((data) => {
                if (data != "NAO_AUTORIZADO") {
                  this.$store.dispatch("snackBarModule/openSnackBar", {
                    color: "success",
                    timeout: 3000,
                    text: "Item registrado com sucesso",
                  });
                }
              })
              .catch(() => {
                this.$store.dispatch("snackBarModule/openSnackBar", {
                  color: "error",
                  timeout: 3000,
                  text: "Houve um erro ao cadastrar motivo.",
                });
              })
              .finally(() => {
                this.loadingTabela = false;
              });
          } else {
            const index = this.motivos.indexOf(this.motivo);

            this.loadingTabela = true;
            this.$store
              .dispatch("motivos/atualizarMotivo", this.motivo)
              .then((data) => {
                if (data != "NAO_AUTORIZADO") {
                  this.motivos.splice(index, 1, data);
                  this.$store.dispatch("snackBarModule/openSnackBar", {
                    color: "success",
                    timeout: 3000,
                    text: "Item atualizado com sucesso",
                  });
                }
              })
              .catch(() => {
                this.$store.dispatch("snackBarModule/openSnackBar", {
                  color: "error",
                  timeout: 3000,
                  text: "Houve um erro ao buscar todos os dados do motivo selecionado.",
                });
              })
              .finally(() => {
                this.loadingTabela = false;
              });
          }
          return this.fechar();
        }
      });
    },
    editar(item) {
      this.nameForm = "Editar motivo: " + item.descricao;
      this.motivo = item;
      this.motivo.submotivos = item.submotivos;
      this.editedIndex = true;
      this.dialog = true;
    },
    fechar() {
      this.dialog = false;
      this.editedIndex = false;
      this.nameForm = "Novo motivo";
      this.motivo = {
        descricao: "",
        status: "A",
        submotivos: [],
      };
      this.loading = false;
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = item;
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.motivos.findIndex((el) => el.id == this.item.id);

        this.$store
          .dispatch("motivos/deletarMotivo", this.item.uuid)
          .then((response) => {
            if (response.data === true) {
              this.motivos.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Registro removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o registro.",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
          });
      }
    },
    //DATATABLE COSTUMIZATION
    getColor(status) {
      if (status == "I" || status == "Inativo") return "error";
      if (status == "A" || status == "Ativo") return "success";

      return "primary";
    },
    iconSelect(tipo, item) {
      if (this.submotivos && this.motivo.submotivos) {
        if (this.submotivos.length == this.motivo.submotivos.length)
          return "mdi-close-box";
        if (this.motivo.submotivos.length > 0) return "mdi-minus-box";
      }
      return "mdi-checkbox-blank-outline";
    },
    toggleSelect(tipo) {
      this.submotivos.length == this.motivo.submotivos.length
        ? (this.motivo.submotivos = [])
        : (this.motivo.submotivos = this.submotivos.map((t) => t.id));
    },
  },
};
</script>

<style scoped></style>
